<template>
    <v-dialog v-model="isOpen" width="50%">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Planifier un rendez-vous
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="mt-2">
                <div class="d-flex align-center">
                    <p class="mr-4 mb-0">
                        Rendez-vous <b> {{ appointment.subject }} </b>
                        d'une durée de <b> {{ appointment.duration | minutesToHours() }} </b>
                        <template v-if="isMinAndMaxDateTheSame">
                            à fixer le <b> {{ appointment.minDate | toDate() }} </b>
                        </template>
                        <template v-else-if="appointment.minDate && appointment.maxDate">
                            à fixer entre le <b> {{ appointment.minDate | toDate() }} </b> et le <b> {{ appointment.maxDate | toDate() }} </b>
                        </template>
                        <template v-else-if="appointment.minDate">
                            à fixer a partir du <b> {{ appointment.minDate | toDate() }} </b>
                        </template>
                        <template v-else-if="appointment.maxDate">
                            à fixer avant le <b> {{ appointment.maxDate | toDate() }} </b>
                        </template>
                        avec <b> {{ appointment.intervenants[0].name }} {{ appointment.intervenants[0].firstname }} </b>
                    </p>
                </div>

                <v-expansion-panels class="mt-4">
                    <v-expansion-panel v-for="(disponibility, i) of displayDisponibilities" :key="i">
                        <v-expansion-panel-header>
                            <span class="text-uppercase text-caption font-weight-bold">
                                {{ disponibility.startDate | toDay() }} {{ disponibility.startDate | toDayNumber() }} {{ disponibility.startDate | toMonth() }}
                            </span>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <ConfirmationDialog v-for="(hour, i) of disponibility.hours" :key="i" @confirm="createInstance(disponibility, hour)" :text="getHourConfirmationText(disponibility, hour)">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="blue" class="mr-4 mb-4" dark v-on="on">
                                        {{ hour | toTime(false) }}
                                    </v-btn>
                                </template>
                            </ConfirmationDialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>

            <v-divider />

            <v-card-actions>

                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { isSameDay, addMinutes } from 'date-fns';
import appointmentsMixin from '../../../mixins/appointments.js';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog.vue';

export default {
    name: 'CreateAppointmentInstanceDialog',

    mixins: [appointmentsMixin],

    props: {
        appointment: { type: Object, required: true }
    },

    components: {
        ConfirmationDialog
    },

    data: () => ({
        isOpen: false,
        instances: [],
        disponibilities: []
    }),

    computed: {
        isMinAndMaxDateTheSame() {
            return isSameDay(new Date(this.appointment.minDate), new Date(this.appointment.maxDate));
        },
        
        displayDisponibilities() {
            let disponibilities = [];

            for (const disponibility of this.disponibilities) {
                disponibility.hours = this.getDisponibilityHours(this.appointment, this.instances, disponibility);

                const disponibilityTheSameDay = disponibilities.find((d) => {
                    return isSameDay(new Date(d.startDate), new Date(disponibility.startDate));
                });
                if (disponibilityTheSameDay) {
                    for (const hour of disponibility.hours) {
                        const sameHour = disponibilityTheSameDay.hours.find((h) => h.getTime() === hour.getTime());
                        if (!sameHour) {
                            disponibilityTheSameDay.hours.push(hour);
                        }
                    }
                    disponibilityTheSameDay.hours.sort((a, b) => a.getTime() - b.getTime());
                } else {
                    disponibilities.push(disponibility);
                }
            }

            disponibilities = disponibilities.filter((d) => d.hours.length > 0);

            return disponibilities;
        }
    },

    watch: {
        isOpen() {
            if (this.isOpen) {
                this.getDisponibilities();
            }
        }
    },

    methods: {
        async getDisponibilities() {
            try {
                this.setLoading(true);
                const query = {
                    include: 'instances'
                };
                const { instances, disponibilities, err } = await this.repos.appointments.getIntervenantDisponibilities(this.appointment.intervenants[0].id, query);

                if (err) {
                    throw new Error(err);
                }

                this.instances = instances;
                this.disponibilities = disponibilities;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la récupération des disponibilités',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async createInstance(disponibility, hour) {
            try {
                this.setLoading(true);
                const body = {
                    instance: {
                        appointmentId: this.appointment.id,
                        intervenantId: this.appointment.intervenants[0].id,
                        startDate: hour,
                        buyerEmail: this.$store.state.application.user.email,
                        endDate: addMinutes(hour, this.appointment.duration)
                    }
                };

                const { success, err } = await this.repos.appointments.createAppointmentInstance(body);
                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'votre rendez-vous à bien été planifié',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.$emit('created');
                    });
                    this.isOpen = false;
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la saisie du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        getHourConfirmationText(disponibility, hour) {
            const endHour = addMinutes(hour, this.appointment.duration);
            return `Confirmer ce rendez-vous ${ this.$options.filters.toDayMonth(disponibility.startDate) } de ${this.$options.filters.toTime(hour, false)} à ${this.$options.filters.toTime(endHour, false)}`;
        }
    }
};
</script>
