<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-subtitle class="green--text">
                <v-tooltip :disabled="!appointment.comments" top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ appointment.subject }}
                        </span>
                    </template>
                    {{ appointment.comments }}
                </v-tooltip>
            </v-list-item-subtitle>

            <v-list-item-subtitle>
                <small>
                    Rendez-vous le {{ appointment.instances[0].startDate | toDatetime(false) }},
                    <template v-if="appointment.duration">
                        durée : {{ appointment.duration | minutesToHours() }},
                    </template>
                    <template v-if="appointment.location">
                        lieu: {{ appointment.location }},
                    </template>
                    <template v-if="appointment.intervenants[0].fullname">
                        avec : {{ appointment.intervenants[0].fullname }}
                    </template>
                </small>
            </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <ConfirmationDialog @confirm="cancelInstance()" text="Êtes-vous sûr de vouloir annuler ce rendez-vous ?">
                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon v-on="{ ...dialog, ...tooltip }">
                                <v-icon> mdi-calendar-remove </v-icon>
                            </v-btn>
                        </template>
                        Annuler le rendez-vous
                    </v-tooltip>
                </template>
            </ConfirmationDialog>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import ConfirmationDialog from '../../dialogs/ConfirmationDialog.vue';

export default {
    name: 'AppointmentsListItem',

    props: {
        appointment: { type: Object, required: true }
    },

    components: {
        ConfirmationDialog
    },

    methods: {
        async cancelInstance() {
            try {
                this.setLoading(true);

                const { success, err } = await this.repos.appointments.cancelAppointmentBuyer(this.appointment.instances[0].id);
                if (success) {
                    this.$emit('reload');
                    this.isOpen = false;
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la suppression du rendez-vous',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>